exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-environmental-sustainability-js": () => import("./../../../src/pages/about-us/environmental-sustainability.js" /* webpackChunkName: "component---src-pages-about-us-environmental-sustainability-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-about-us-meet-our-team-js": () => import("./../../../src/pages/about-us/meet-our-team.js" /* webpackChunkName: "component---src-pages-about-us-meet-our-team-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-transportation-canadian-based-carriers-js": () => import("./../../../src/pages/transportation/canadian-based-carriers.js" /* webpackChunkName: "component---src-pages-transportation-canadian-based-carriers-js" */),
  "component---src-pages-transportation-intra-canadian-brokers-js": () => import("./../../../src/pages/transportation/intra-canadian-brokers.js" /* webpackChunkName: "component---src-pages-transportation-intra-canadian-brokers-js" */),
  "component---src-pages-transportation-intra-canadian-carriers-js": () => import("./../../../src/pages/transportation/intra-canadian-carriers.js" /* webpackChunkName: "component---src-pages-transportation-intra-canadian-carriers-js" */),
  "component---src-pages-transportation-js": () => import("./../../../src/pages/transportation.js" /* webpackChunkName: "component---src-pages-transportation-js" */),
  "component---src-pages-transportation-us-based-brokers-js": () => import("./../../../src/pages/transportation/us-based-brokers.js" /* webpackChunkName: "component---src-pages-transportation-us-based-brokers-js" */),
  "component---src-pages-transportation-us-based-carriers-js": () => import("./../../../src/pages/transportation/us-based-carriers.js" /* webpackChunkName: "component---src-pages-transportation-us-based-carriers-js" */),
  "component---src-pages-what-we-trade-js": () => import("./../../../src/pages/what-we-trade.js" /* webpackChunkName: "component---src-pages-what-we-trade-js" */),
  "component---src-templates-alt-page-template-js": () => import("./../../../src/templates/alt-page-template.js" /* webpackChunkName: "component---src-templates-alt-page-template-js" */),
  "component---src-templates-what-we-trade-template-js": () => import("./../../../src/templates/what-we-trade-template.js" /* webpackChunkName: "component---src-templates-what-we-trade-template-js" */)
}

